import React from 'react';

export default (state={loading:false, response:null,}, action) => {
    //  console.log("Login reducer "+action.type);

      if ( action.type == 'LOGIN_REQUEST') {
        return { loading: true };
      }
      if ( action.type == 'LOGIN_REQUEST_SUCCESS') {
        //console.log("Login successful loading: "+state.loading);
        return { loading: false, response: action.action };
      }
      if ( action.type == 'LOGIN_REQUEST_FAILURE') {
        //console.log("Login failure loading: "+action.error);
        return { loading: false, response: action.error };
      }
      if ( action.type == 'USER_REQUEST') {
        return { loading: true };
      }
      if ( action.type == 'USER_REQUEST_SUCCESS') {
        //console.log("User successful loading: "+state.loading);
        return { loading: false, user: action.user, response: "success" };
      }
      if ( action.type == 'USER_REQUEST_FAILURE') {
        //console.log("Login failure loading: "+action.error);
        return { loading: false, user: null, response: action.error };
      }
      if ( action.type == 'REGISTER_REQUEST' ) {
        return { loading: true };
      }
      if ( action.type == 'REGISTER_REQUEST_SUCCESS' ) {
        return { loading: false, response: action.action };
      }
      if ( action.type == 'REGISTER_REQUEST_FAILURE' ) {
        return { loading: false, response: action.error };
      }

      return { loading: false };
}
