import React from 'react';

export default function HalalistIcon (props) {
  return (
    <svg width="24" height="24" fill="#e6e6e6">
     <g class="layer">
      <title>Layer 1</title>
      <rect fill="currentColor" height="21" id="svg_5" rx="20" ry="20" stroke="#bdbdbd"
        width="21" x="1.8125" y="1.35156"/>
      <text fill="#ffffff" font-family="Times" font-size="24" id="svg_11"
        stroke="#bdbdbd" stroke-dasharray="2,2" stroke-linecap="round"
        stroke-width="0" text-anchor="middle" x="12.875"
        y="19.85156">h</text>
     </g>
    </svg>
  );
}
