import React from 'react';
import axios from 'axios';
import { delay } from 'redux-saga/effects';

//const API_URL="http://localhost:8180/";
const API_URL= process.env.REACT_APP_API_URL;

/*
* https://github.com/axios/axios
* You can intercept requests or responses before they are handled by then or catch.
*/
axios.interceptors.request.use(function(request) {
  //console.log("Interceptor..."+sessionStorage.getItem("token"));
  //axios.defaults.headers.common['Authorization'] = "Bearer "+sessionStorage.getItem("token");
  request.headers.Authorization = sessionStorage.getItem("token");
  return request;
}, function(error) {

});

axios.interceptors.response.use(function (response) {
  //console.log("Axios interceptor request was successful!");
  return response;
}, function (error) {
  //console.log("Axios interceptor request failed. Error code: "+error);
  if ( error.response && error.response.status === 401 ) {
    //console.log("error status: "+error.response.status);
    //clear token
    sessionStorage.setItem("token", "");
    window.location.reload();
  }
  return Promise.reject(error);
});

export const hasToken = () => {
  let token = sessionStorage.getItem("token");
  return token ? true : false;
}

export const authenticate = (user, pass) => {
  return axios.post(API_URL+"token", {"username":user,"secret":pass})
    .then(res => {
      //console.log(res.data);
      const { token } = res.data ;
      sessionStorage.setItem("token", token);
      return res.data;
    }).catch(error => {
        console.log("Error logging in "+error);
        //return Promise.reject(error.response);
        //throw error;
        return error;
      }
    )

}

export const register = (data) => {
  //console.log("Register: "+JSON.stringify(data));
  return axios.post(API_URL+"register", data)
    .then(response => {
      //console.log("Register response: "+JSON.stringify(response));
      return response.data;
    }).catch(error => {
        return error;
      }
    )
}

export const loadUser = () => {
  return axios.get(API_URL+"user", {})
  .then(response => {
    console.log("loaduser response: "+response);
    return response;
  }).catch(error => {
    return error;
  })
}

export const publish = (payload) => {
  var data = new FormData();

  data.append("payload", new Blob( [JSON.stringify(payload.values)], { type: "text/json" } ));
  data.append("image", payload.file);

  return axios({
    method: "post",
    url: API_URL+"publish",
    headers: {"Content-Type": "multipart/form-data"},
    data: data
  }).then(res => {
    return res;
  }).catch(error => {
      return error;
    }
  )
}
export const schedules = (payload) => {
  //console.log("Get schedules now...");
  return axios({
    method: "get",
    url: API_URL+"schedules",
  }).then(res => {
    return res;
  }).catch(error => {
    console.log("Unable to call schedules end point.");
    return error;
  })
}
export const loadScheduledContent = (eventid) => {
  //console.log("Loading scheduled content");
  return axios({
    method: "get",
    url: API_URL+"schedule/"+eventid,
  }).then(res => {
    return res;
  }).catch(error => {
    console.log("Unable to load scheduled content end point.");
    return error;
  })
}
export const save = (payload) => {
  var data = new FormData();

  data.append("payload", new Blob( [JSON.stringify(payload.values)], { type: "text/json" } ));
  data.append("image", payload.file);

  return axios({
    method: "post",
    url: API_URL+"save",
    headers: {"Content-Type": "multipart/form-data"},
    data: data
  }).then(res => {
    return res;
  }).catch(error => {
      return error;
    }
  )
}


const loggedIn = () => ({
  // setTimeout(() => {
  //   console.log("Timeout called...");
  //   //setLoading(false);
  // },80000);
  //return {
    loaded: true,
  //}
});
export const updateUserMedia = (payload) => {
  //console.log("Updating user media: "+JSON.stringify(payload));
  return axios.post(API_URL+"generatekeys", { name: payload.media.name })
    .then(res => {
      //console.log("Media response: "+JSON.stringify(res));

      return res;
    }).catch(error => {
        //console.log("Error updating user media in "+error);
        return error;
      }
    )
}

export const deleteUserMedia = (payload) => {
  //console.log("Deleting user media: "+JSON.stringify(payload));
  return axios.delete(API_URL+"usermedia", { data: payload.usermediareq, headers:{} })
    .then(res => {
      //console.log("Media response: "+JSON.stringify(res));
      return res;
    }).catch(error => {
        //console.log("Error deleting user media "+error);
        return error;
      }
    )
}
