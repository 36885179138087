export const auth = (user, pass, cb ) => ({
  type: "LOGIN_REQUEST",
  payload: { user, pass },
  cb
});

export const doregister = ( data, cb ) => ({
  type: "REGISTER_REQUEST",
  payload: { data },
  cb
});
