import React from 'react';

class Auth {
  //this needs to be updated with auth code
  constructor() {
    console.log("Init auth..");
    this.authenticated = false;
  }

  isAuthenticated() {
    console.log("---Debugging isAuthentitcated: "+this.authenticated);
    return this.authenticated;
  }
}
export default (new Auth());
