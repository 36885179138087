import React from 'react';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import { Box, Button, Card, CardHeader, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import { array, boolean, mixed, number, object, string } from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../alert/';
import SendIcon from '@material-ui/icons/Send';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { publish } from './actions.js';
import { connect } from 'react-redux';
import { MediaList } from './medialist';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { loadUser } from '../../actions/sidenav';
import CustomizedMenus from './actionmenu';


export class PublishForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bindSubmitForm: props.bindSubmitForm,
      bindSaveForm: props.bindSaveForm,
      formValues: props.formValues,
      user: props.user,
      initvalues: props.initvalues,
      formikProps: null,
    }
  }
  render() {
    const { bindSubmitForm, bindSaveForm, formValues, user, initvalues, formikProps } = this.state;
    //const user = this.props.user;
    //const formikProps = this.props.formikProps;
    return (
      <Formik
        initialValues={initvalues}
        validationSchema={
          object({
            content: string().required('Content is required').min(1).max(1000),
            media: string().required('Please select at least on site to publish content'),

          })
        }
        onSubmit={(values) => {
          console.log("Submit values "+values);
          formValues(values);
        } }
        handleChange={() => {
          console.log("Change.....");
        }}
      >
      {(formikProps) => {
        const { values, errors, isSubmitting, isValidating, handleSubmit, handleChange } = formikProps ;
        bindSubmitForm(formikProps.submitForm);
        bindSaveForm(formikProps);
        return (
          <Form>
            <MediaList user={this.props.user}/>
            { /*only show for hl */ }
            {  values && values.media && values.media.includes("hl") &&
                <Box marginBottom={2}>
                  <FormGroup>
                    <Field as={TextField} name="title" label="Title" variant="outlined"/>
                    <Typography color="error"><ErrorMessage name="title" /></Typography>
                  </FormGroup>
                </Box>

            }
            <Box marginBottom={2}>
              <FormGroup>
                <Field as={TextField} multiline rows={5} name="content" label="Content" variant="outlined"/>
                <Typography color="error"><ErrorMessage name="content" /></Typography>
              </FormGroup>
            </Box>
            <Box marginBottom={2}>

            </Box>

            {/*
            <Button
              className={classes.button}
              endIcon={<ScheduleIcon/>}
              type="submit"
              disabled={this.state.isSubmitting || isValidating }
              variant="outlined" color="primary">
              Schedule
            </Button>&nbsp;
            <Button
              className={classes.button}
              endIcon={<SaveIcon/>}
              type="submit"
              disabled={this.state.isSubmitting || isValidating }
              variant="outlined" color="primary">
              Save
            </Button>

            <pre>{JSON.stringify(errors, null, 4)}</pre>
            <pre>{JSON.stringify(values, null, 4)}</pre> {/*
            <pre>{JSON.stringify(this.props.response, null, 4)}</pre>
            <pre>{JSON.stringify(this.state.alert, null, 4)}</pre>
            <pre>{JSON.stringify(this.state.response, null, 4)}</pre>*/}

          </Form>
        )
      }}
      </Formik>
    )
  }
}
