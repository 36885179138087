import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faChartLine, faCog, faKey,
  faPaperPlane, faPlus, faCalendar, faHistory } from '@fortawesome/free-solid-svg-icons'
//import styled from 'styled-components';
import '../../nav.css';

export default class SideBar extends Component {
  state = {
    expanded: false
  };

  propTypes : {
    onChange: React.PropTypes.func,
    onSelect: React.PropTypes.func
  }

  render() {
    const { user } = this.props;
    return (
        <>
        <div className="app">
        <SideNav props={user}
          onSelect={(selected) => {
              // Add your code here
              //console.log("Selected item: "+selected);
              this.props.onSelect(selected);

          }}
          onToggle= {(expanded) => {
              //console.log("Toggle side bar "+expanded);
              this.setState( {expanded:expanded} );
              this.props.onChange(expanded);
          }
          }
        >
          <SideNav.Toggle />
          <SideNav.Nav defaultSelected="home">
              <NavItem eventKey="home">
                  <NavIcon>
                    <FontAwesomeIcon icon={faHome} />
                  </NavIcon>
                  <NavText>
                      HOME
                  </NavText>
              </NavItem>
              <NavItem eventKey="content">
                  <NavIcon>
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </NavIcon>
                  <NavText>CONTENT</NavText>
                    <NavItem eventKey="content/addpost">
                        <NavText>
                            <FontAwesomeIcon icon={faPlus} /> Add Post
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="content/schedule">
                        <NavText>
                            <FontAwesomeIcon icon={faCalendar} /> Schedule
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="content/posthistory">
                        <NavText>
                            <FontAwesomeIcon icon={faHistory} /> History
                        </NavText>
                    </NavItem>
              </NavItem>
              <NavItem eventKey="settings">
                  <NavIcon>
                    <FontAwesomeIcon icon={faCog} />
                  </NavIcon>
                  <NavText>SETTINGS</NavText>
                  <NavItem eventKey="settings/apikeys">
                      <NavText>
                          <FontAwesomeIcon icon={faKey} /> APIs
                      </NavText>
                  </NavItem>
              </NavItem>
{/*
              <NavItem eventKey="testing">
                  <NavIcon>
                    <FontAwesomeIcon icon={faChartLine} />
                      <i className="fa fa-fw fa-line-chart" style={{ fontSize: '1.75em' }} />
                  </NavIcon>
                  <NavText>
                      Charts
                  </NavText>

                  <NavItem eventKey="chart">
                      <NavText>
                          Line Chart
                      </NavText>
                  </NavItem>
                  <NavItem eventKey="testing">
                      <NavText>
                          Bar Chart
                      </NavText>
                  </NavItem>
              </NavItem>*/}
          </SideNav.Nav>
        </SideNav>


        </div>
        </>
    );
  }
}
