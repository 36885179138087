import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { schedules } from '../actions';
import PostModal from './postmodal';

class Scheduler extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      response: "",
      currentEvents: [],
      open: false,
      handleClose: () => {
        this.setState({ open: false });
      },
      events: [],
      user: props.location.state.user,
      selectedEventId: "",
    }
  }

  handleDateSelect = (selectInfo) => {
    //allow user to create new event
    this.setState({open: !this.state.open, selectedEventId: null});
    //console.log("Date selected: "+selectInfo);
    // let title = window.prompt('Please enter a new title for your event')
    // let calendarApi = selectInfo.view.calendar
    //
    // calendarApi.unselect() // clear date selection
    //
    // if (title) {
    //   calendarApi.addEvent({
    //     id: 1000,
    //     title,
    //     start: selectInfo.startStr,
    //     end: selectInfo.endStr,
    //     allDay: selectInfo.allDay
    //   })
    // }
  }

  handleEventClick = (clickInfo) => {
    console.log("Event clicked..."+clickInfo.event.id+" ");

    this.setState({open: !this.state.open, selectedEventId: clickInfo.event.id});
    // if (window.confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //   clickInfo.event.remove()
    // }
  }

  handleDates = ( rangeInfo ) => {
    this.props.loadSchedules(rangeInfo);
  }

  handleEvents = (events) => {
    console.log("Events occurred...");
    //this.setState({ currentEvents: events });
  }

  componentDidMount()  {
    //props.loadSchedules("");
    //response = dispatch(schedules(""));
    if ( !this.state.response ) {
      var res = this.props.loadSchedules("");
      //console.log("Res: "+JSON.stringify(res));
    }

  }

  componentWillReceiveProps = (nextProps) => {
    //console.log("Next props: "+JSON.stringify(nextProps));
    if ( nextProps.events ) {
      this.setState( { events: nextProps.events} );
    }
    // if ( nextProps.response ) {
    //   console.log("Next props: "+JSON.stringify(nextProps));
    //   var elist = [];
    //   nextProps.response.map( obj => {
    //     var evt = new Object();
    //     evt.id = obj.id;
    //     evt.title = obj.name;
    //     evt.start = "2020-06-28T09:00:00"
    //     elist.push(evt);
    //   })
    //   this.setState( { currentEvents: elist});
    //
    // }
  }

  render() {

    return (
      <>
      <Card>
        <CardHeader title="Schedule"></CardHeader>
        <CardContent>
          <FullCalendar
            plugins={ [ dayGridPlugin, timeGridPlugin, interactionPlugin ]}
            headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'dayGridMonth,timeGridWeek,timeGridDay'
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            datesSet={this.handleDates}
            eventContent={this.renderEventContent}
            select={this.handleDateSelect}
            eventClick={this.handleEventClick}
            eventsSet={this.handleEvents} // called after events are initialized/added/changed/removed
            //initialEvents={currentevt}
            events={this.state.events}

          />
          <PostModal
            open={this.state.open}
            user={this.state.user?this.state.user:this.props.location.state.user}
            eventid={this.state.selectedEventId}
            history ={this.props.history}
            handleOpen={()=>{
              this.setState({ open: !this.state.open});
            }}
            handleClose={()=>{
              this.setState({ open: !this.state.open});
            }}
            />
        </CardContent>
      </Card>

      {/*
      <pre>{JSON.stringify(this.props.events, null, 4)}</pre>
      */}
      </>
    )
  }
}

function renderEventContent(eventInfo) {
  console.log("Rendering events: "+eventInfo);
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  loadSchedules: () => dispatch(schedules())
});

function mapStateToProps() {
  const getEvents = (state) => {
    var events = null;
    state.publisher.schedules && state.publisher.schedules.map( obj => {
      var evt = new Object();
      evt.id = obj.id;
      evt.title = obj.name;
      evt.start = obj.start;
      evt.end = obj.end;
      if ( !events )
        events=[];
      events.push(evt);
    });
    //console.log("Events: "+events);
    return events;
  }
  return (state) => {
    return {
      events: getEvents(state)
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scheduler);
