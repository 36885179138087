import React, { useEffect } from 'react';
import { Link,Box, Button, Card, CardHeader, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { updateUserMedia, deleteUserMedia } from '../../../actions/media';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Alert from '../../alert/';

export function BasicApiKeys(props) {
  const { user } = props.props ;
  const { title, media, jkey } = props;
  const [ isSubmitting, setSubmitting ] = React.useState(false);
  const usermedia = media && media.usermedia;
  //const ouser = usermedia;
  const [accesstoken, setAccessToken] = React.useState(usermedia && usermedia.accesstoken);
  const [ats, setAts] = React.useState(usermedia && usermedia.accessTokenSecret);
  const [alert, setAlert] = React.useState(false);
  const dispatch = useDispatch();
  const response = useSelector(state => state.media.response);
  const { push } = useHistory();


  const handleSave = (e) => {
    //console.log("Saving API..");
    setSubmitting(true);
    const media = {
      name: jkey
    }
    dispatch(updateUserMedia( { media } ));
  }

  const handleDelete = (e) => {
    //console.log("Deleting API..");
    setSubmitting(true);
    const usermediareq = {
      userid: user.id,
      id: usermedia.id,
      accesstoken: accesstoken,
      accessTokenSecret: ats,
      mediaid: media.id
    }
    setAlert( {
      open: true,
      title: "Are you sure?",
      content: <>Please confirm that you want to delete the keys. Once deleted you will have to add the keys again to be able to post.</>,
      handleClose: () => {
        setAlert(!alert);
        setSubmitting(false);
      },
      closeLabel: "Cancel",
      nextLabel: "Delete",
      handleNext: () => {
        setAlert(!alert);
        //console.log("Deleting....");
        dispatch(deleteUserMedia( { usermediareq } ));
      },
    });

  }
  //console.log("otwit: "+JSON.stringify(user));
  useEffect(() => {
    //console.log("Api keys....."+response);
    //const alink ="https://google.com";
    //if ( !response ) {
    if ( response && response.authorizationURL ) {
      //setTimeout( () => window.open(response.authorizationURL,"scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=300,left=100,top=100"), 1000 );
      setAlert( {
        open: true,
        title: "Please grant us permissions",
        content: <>To complete your request we would need your permission to access your account please <a href={response.authorizationURL} target='_blank'>click here</a> to grant us access</>,
        handleClose: ()=>{
          setAlert(!alert);
          push( {
          pathname: "/home",
          state: { user: user }
          });
          window.location.reload();
        },
        closeLabel: "Close",
      });
    } else if ( response && response.result=="success") {

      setAlert( {
        open: true,
        title: "Success!",
        content: "You have succefully updated API keys",
        handleClose: ()=>{
          setAlert(!alert);
          push( {
          pathname: "/home",
          state: { user: user }
          });
          window.location.reload();
        },
        closeLabel: "Close",
      });
    }
    setSubmitting(false);
  }, [JSON.stringify(response)])

  return (
    <>
    <Card>
      <CardHeader title={ title + " API keys"}></CardHeader>
      <CardContent>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="Access Token"
              value={ accesstoken || '' }
              disabled = { true }
              onChange = {(e) => { setAccessToken(e.target.value) }}
              fullWidth/>
          </FormGroup>
        </Box>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="Access Token Secret" type="password"
              value={ ats || '' }
              disabled = { true }
              onChange = {(e) => { setAts(e.target.value) }}
              fullWidth/>
          </FormGroup>
        </Box>
        { ( (!media && !usermedia) || (media && usermedia && usermedia.status!='A') ) &&
          <Box marginBottom={2}>
            <Button
              onClick={handleSave}
              endIcon={<SaveIcon/>}
              type="button"
              disabled={isSubmitting  }
              variant="outlined" color="primary">
              Generate Keys
            </Button>
          </Box>
        }
        { media && usermedia && usermedia.status=='A' &&
          <Box marginBottom={2}>
            <Button
              onClick={handleDelete}
              endIcon={<DeleteForeverIcon/>}
              type="button"
              disabled={isSubmitting  }
              variant="outlined" color="secondary">
              Delete
            </Button>
          </Box>
        }
        {/**
        <pre>{JSON.stringify(response, null, 4)}</pre>
        <pre>{JSON.stringify(ouser)}</pre>{/**/}
      </CardContent>
    </Card>
    <Alert
      open={alert.open}
      handleClose={alert.handleClose}
      handleNext={alert.handleNext}
      title={alert.title}
      content={alert.content}
      closeLabel={alert.closeLabel}
      nextLabel={alert.nextLabel}
      />
    </>
  )

}
