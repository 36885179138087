import React, {useEffect} from 'react';
import { BasicApiKeys } from './BasicApiKeys';
import { getObjectFromArray } from '../../../common/jsonutil';

export function FBKeys(props) {
  const { user } = props;
  const media = user && getObjectFromArray(user.media, 'name', 'fb');
  return (
    <>
    <BasicApiKeys props={props} title="Facebook"
      media={media}
      jkey="fb" />
    </>
  )
}
