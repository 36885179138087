import React, {useEffect} from 'react';
import { BasicApiKeys } from './BasicApiKeys';
import { getObjectFromArray } from '../../../common/jsonutil';

export function TwitKeys(props) {
  const { user } = props;
  const media = user && getObjectFromArray(user.media, 'name', 'twit');
  //console.log("Has Twit keys: "+media);
  return (
    <>
      <BasicApiKeys props={props}
        media={media}
        title="Twitter"
        jkey="twit" />
    </>
  )
}
