import React from 'react';
import { Box, Card, CardHeader, CardContent, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import HalalistIcon from '../../../images/hl';
import HelpIcon from '@material-ui/icons/Help';
import AddIcon from '@material-ui/icons/Add';
import ThumbDown from '@material-ui/icons/ThumbDown';
import ThumbUp from '@material-ui/icons/ThumbUp';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {TwitKeys} from './twitter.js';
import {FBKeys} from './fb.js';
import {NoKeys} from './nokeys.js';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <>
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography component={'span'} variant={'body'}>{children}</Typography>
          </Box>
        )}
      </div>
    </>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function APIKeys(props) {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const { user } = props.location.state;
  //console.log("setting user: "+JSON.stringify(user));
  const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  return (
    <>
    <br/>
    <Card>
      <CardHeader title="Media APIs"></CardHeader>
      <CardContent>
        <Box marginBottom={2}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="scrollable force tabs example"
          >
            <Tab label="Twitter" icon={<TwitterIcon />} {...a11yProps(0)} />
            <Tab label="Facebook" icon={<FacebookIcon />} {...a11yProps(1)} />
            <Tab label="Halalist" icon={<HalalistIcon />} {...a11yProps(2)} />
            <Tab label="Add More" icon={<AddIcon />} {...a11yProps(4)} />
            {/*
            <Tab label="Item Six" icon={<ThumbDown />} {...a11yProps(5)} />
            <Tab label="Item Seven" icon={<ThumbUp />} {...a11yProps(6)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <TwitKeys props={props} user={user} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <FBKeys props={props} user={user} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <NoKeys props={props} user={user} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <NoKeys props={props} user={user} />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <NoKeys props={props} user={user}/>
        </TabPanel>
        </Box>
      </CardContent>
    </Card>
    </>
  )
}
