import React from 'react';
import AppNav from '../components/nav/index.js';
import { AppRoutes } from './approutes.js';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { hasToken } from '../common/api.js';
// This object may any routes that dont required authentication
// Once authenticated all app routes are defined in app routes

const wrapped = (props) => (
  <AppNav {...props}>
    <AppRoutes/>
  </AppNav>
);

export const ProtectedRoute = ({ component: Component, token, ...rest }) => {


  //console.log("token to check: "+hasToken());
  return (
    hasToken() ? (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={ wrapped } />
          <Route path="" component={wrapped} />
          <Route path="*" component={() => "404 Not Found"} />
        </Switch>
      </BrowserRouter>
    )
    : (
      <Redirect to={{
          pathname: '/login'
        }}/>
    )
  )
}

// export const ProtectedRoute = ({ component: Component, ...rest }) => {
//   return (
//     <Route {...rest}
//       render={props => {
//         if ( auth.isAuthenticated() ) {
//           return { wrapped } ;
//         } else {
//           console.log("Unauthorized request taking to login page");
//           return <Redirect to={
//             {
//               pathname: "/",
//               state: {
//                 from: props.location
//               }
//             }
//           } />
//         }
//       }}
//       />
//   );
// }
