import React, {Component} from 'react';
import LandingContent from './content';

export default class Dash extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log("Mounting dashbord...");
  }

  render() {
    return (
      <div>
        <LandingContent props={this.props} />
      </div>
    );
  }
}
