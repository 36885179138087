import { call, all, spawn } from 'redux-saga/effects';
import { watchLoadData as testsaga } from './testsaga';
import { watchPublish as postsaga } from '../components/post/saga';
import { watchAuth as authsaga, watchRegister as registersaga } from './login';
import { watchLoadUser as loadusersaga } from './sidenav';
import { watchUpdateUserMedia as usermedia, watchDeleteUserMedia as deleteusermedia } from './media';

//https://redux-saga.js.org/docs/advanced/RootSaga.html
export default function* rootSaga() {
  const sagas = [
    testsaga,
    postsaga,
    authsaga,
    registersaga,
    loadusersaga,
    usermedia,
    deleteusermedia,    //add more sagas here
  ];

  yield all( sagas.map( saga =>
    spawn(function* () {
      while(true) {
        try {
          yield call(saga);
          break;
        } catch(e) {
          console.log(e);
        }
      }
    })
  ))
}
