import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import socialImg from '../../images/social.png';
import { useHistory } from 'react-router';

const useStyles = makeStyles({
  root: {
    maxWidth: 445,
  },
});
//Purpose of this function is to show information and action items when
//the user first logs in
export default function LandingContent(props) {

  const classes = useStyles();
  const { push } = useHistory();
  const { user } =  props.props.location.state ? props.props.location.state : "";
  const handleAddPost = () => {
    push( {
      pathname: "/content/addpost",
      state: { user: user }
    })
  }
  return (
    <div>
      <br/>
      <div>
      <Card className={classes.root} >
        <CardActionArea onClick={handleAddPost} >
          <CardMedia
            component="img"
            alt="Social Media"
            height="180"
            image={socialImg}
            title="Social Media"
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h5">
              Content
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              You can add content, publish it to multiple social media sites or save or schedule it for later publishing.
              Start now by clicking "Add"
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button size="small" color="secondary">
            Share
          </Button>
          <Button size="small" color="primary" onClick={handleAddPost}>
              Add Content
          </Button>

        </CardActions>
      </Card>
      </div>
    </div>
  )

}
