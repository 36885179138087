import React from 'react';

export default (state={loading:false, response:null,}, action) => {
      //console.log("Init reducer "+action.type);

      //testing action
      if ( action.type == 'ISLOADING') {
        return { loading: false };
      }
      if ( action.type == 'LOADED') {
        return { loading: true };
      }
      //end testing
      if ( action.type == 'REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'REQUEST_DATA_SUCCESS') {
        return { loading: false,  response: action.action };
      }

      if ( action.type == 'PUBLISH_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'PUBLISH_REQUEST_DATA_SUCCESS') {
        //console.log("returning action: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }
      if ( action.type == 'PUBLISH_REQUEST_DATA_FAILURE') {
        //console.log("Publish request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }

      if ( action.type == 'SAVE_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'SAVE_REQUEST_DATA_SUCCESS') {
        //console.log("returning action: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }
      if ( action.type == 'SAVE_REQUEST_DATA_FAILURE') {
        //console.log("Publish request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }

      if ( action.type == 'SCHEDULE_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'SCHEDULE_REQUEST_DATA_SUCCESS') {
        //console.log("returning action: "+action.action+" loading: "+state.loading);
        return { loading: false, schedules: action.action };
      }
      if ( action.type == 'SCHEDULE_REQUEST_DATA_FAILURE') {
        //console.log("Publish request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, schedules: action.action };
      }

      if ( action.type == 'SCHEDULE_LOAD_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'SCHEDULE_LOAD_REQUEST_DATA_SUCCESS') {
        //console.log("returning action: "+action.action+" loading: "+state.loading);
        return { loading: false, content: action.action };
      }
      if ( action.type == 'SCHEDULE_LOAD_REQUEST_DATA_FAILURE') {
        //console.log("Publish request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, content: action.action };
      }

      return { loading: false };
}
