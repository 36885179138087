import { call, put, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../common/api.js';

function* auth(action) {
  console.log("Authenticating user "+action);
  const { user, pass } = action.payload;
  try {
    //yield delay(2000); //this is just for testing purposes please remove

    const { token, error } = yield call( Api.authenticate, user, pass );
    if ( token ) {
      yield put({ type: "LOGIN_REQUEST_SUCCESS", token });
      action.cb(token);
    } else {
      throw(new Error("Error! "+error));
    }
    //callback(true);
  } catch (err) {
    //callback(false);
    console.log("Error while logging in "+err);
    yield put({ type: "LOGIN_REQUEST_FAILURE", error: err });
  }
}

function* register(action) {
  try {
    const error = "";
    const response = yield call( Api.register, action.payload.data );
    if ( response ) {
      yield put({ type: "REGISTER_REQUEST_SUCCESS", response });
    }
    action.cb(response);
  }catch(err) {
    yield put({ type: "REGISTER_REQUEST_FAILURE", error: err });
  }
}

export function* watchAuth() {
  yield takeLatest("LOGIN_REQUEST", auth);

}

export function* watchRegister() {
  yield takeLatest("REGISTER_REQUEST", register);
}
