import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, TextField, Box, InputAdornment, Button } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Lock from '@material-ui/icons/Lock';
import loginImg from '../../images/socialutil.png';
import { auth } from '../../actions/login';
import { connect } from 'react-redux';
//import TwitterLogin from './twitter/';

class Login extends React.Component {

  constructor(props) {
    super(props);
    //console.log("Login location props: "+props.location);
    this.state = {
      username: "",
      password: "",
      erruser: "", //validation check
      errpass: "",
      errresponse: "",
    }
  }

  componentWillReceiveProps = (nextProps) => {
    if ( nextProps.response ) {
      var err = nextProps.response;
      //console.log("Props: "+err);
      this.setState({ errresponse: "Username password not found!" });
      //console.log(this.state.errresponse);
    }
  }

  handleClick = (e) => {
    e.preventDefault();
    //console.log("Login clicked...");
    var err= false;

    if ( !this.state.username ) {
      this.setState({ erruser: "Username is required!" });
      err = true;
    }
    if ( !this.state.password ) {
      this.setState({ errpass: "Password is required!" });
      err = true;
    }

    if ( !err ) {
      this.props.authenticate(this.state.username, this.state.password, (token) => {
        //console.log("Success! token: "+token);
        this.props.history.push("/home");
      });
    }
  }
  render() {
    const { match, location, history } = this.props;

    return (
      <div className="base-container">
        <div className="header"></div>
          <form onSubmit={this.handleClick}>
            <div className="content">
                <div className="image">
                  <img src={loginImg} />
                </div>
                <div className="form">
                  <div className="form-group">
                    <TextField label="Username" name="username"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(evt) => { this.setState({username: evt.target.value, erruser: ""});  } }
                      fullWidth/>
                    <Typography color="error">{this.state.erruser}</Typography>
                    <br/>
                  </div>
                  <div className="form-group">
                    <TextField label="Password" name="password" type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Lock />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(evt) => { this.setState({password: evt.target.value, errpass: ""});  } }
                      fullWidth/>
                    <Typography color="error">{this.state.errpass}</Typography>
                  </div>
                </div>
            </div>
            <div className="footer">
              <Box marginTop={2}>
                <Button variant="outlined" color="primary" type="submit">Login</Button>
                <Typography color="error">{this.state.errresponse}</Typography>
              </Box>
            </div>
          </form>
        <div>{/*this.state.username*/}</div>
      </div>
    )
  }
}
const mapStateToProps = ( state ) => {
  //console.log("mapStateToProps "+state.auth);
  const { response } = state.auth ;
  return {
    response
  }
}
const mapDispatchToProps = dispatch => ({
  authenticate: (user, pass, cb) => dispatch(auth(user, pass, cb))
});
export default connect(mapStateToProps, mapDispatchToProps)(Login);
