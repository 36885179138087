import React from 'react';

export default function PostHistory(props) {

  return (
    <>
    History
    </>
  )
}
