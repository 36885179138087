import React from 'react';
import AppNav from '../components/nav/index.js';
import {ProtectedRoute} from './protectedroute';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import {LandingPage} from '../components/login/index';

export default(props) => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" render={props => <LandingPage {...props} />} />
      <Route exact path="/login" render={props => <LandingPage {...props} />} />
      <ProtectedRoute exact path="*" component={props => <AppNav {...props} />} />
      {/*
      <Route path="*" component={() => "404 Not Found"} />*/}
    </Switch>
  </BrowserRouter>
)
