import React, {Component} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import '@fortawesome/fontawesome/styles.css';
import loginImg from '../../images/socialutilbanner.png';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { blue } from '@material-ui/core/colors';
import Alert from '../alert/';

const useStyles = (theme) => ({
  blue: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
  },
});

class TopNav extends Component {

  state = {
      anchorEl: null,
      alert: false,
      user: { username: "...", firstname: "", lastname: "", email: "" }, //default
  };
  constructor(props) {
    super(props);

  }

  componentWillReceiveProps = (nextProps) => {
    if ( nextProps.user ) {
      //console.log("Received props: "+JSON.stringify(nextProps));
      this.setState( { user: nextProps.user });
    }
  }

  handleClick = (event) => {
    this.setState( { anchorEl: event.currentTarget } );
  }

  handleClose = (event) => {
    //console.log("Close: "+event.currentTarget);
    this.setState( { anchorEl: null } );
  }

  doLogout = (event) => {
    this.props.history.push("/");
    window.location.reload();
  }

  doProfile = (event) => {
    this.props.history.push({
      pathname: "/profile",
      state: { user: this.state.user },
    });
    this.setState({ anchorEl: null,  });
  }

  showAbout = (event) => {
    this.setState({ alert: true, anchorEl: null });
  }

    render() {
        const { classes } = this.props;
        const { name, url, history } = this.props;
        const { anchorEl, user } = this.state;
        return (
          <>
            <header
                className="site-header"
                style={{ borderRadius: 0 }}
            >
            <img src={loginImg} style={{height: '60px', display: 'inline-block'}} />
            <div className="navoptions-right" >
              <Button aria-controls="simple-menu" aria-haspopup="true" onClick={this.handleClick}>
                <Avatar className={classes.blue}>{user.firstname && user.firstname.charAt(0)}{ user.lastname && user.lastname.charAt(0)}</Avatar>
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.doProfile}><Typography variant="caption">Profile</Typography></MenuItem>
                <MenuItem onClick={this.showAbout}><Typography variant="caption">About</Typography></MenuItem>
                <Divider />
                <MenuItem onClick={this.doLogout}><Typography variant="caption">Logout</Typography></MenuItem>
              </Menu>
            </div>
            </header>
            <Alert open={this.state.alert}
              handleClose={()=>{ this.setState({ alert: !alert }) }}
              title="About"
              content="This is beta version of SocialUtil. Stay tuned for more."
              closeLabel="Close"
              />
            </>
        );
    }
}
export default withStyles(useStyles)(TopNav)
