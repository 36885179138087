import { fork, call, put, takeLatest } from 'redux-saga/effects';
import * as Api from '../common/api.js';

function* updateUserMedia(action) {

  try {
    //yield delay(4000); //this is just for testing purposes please remove
    const { data, error } = yield call( Api.updateUserMedia, action.payload.content );
    if ( data )
    yield put({ type: "USERMEDIA_REQUEST_DATA_SUCCESS", action: data });
    else {
      yield put({ type: "USERMEDIA_REQUEST_DATA_FAILURE", action: error});
    }
    //callback(true);
  } catch (err) {
    //callback(false);
    console.log("Error while updating user media"+err);
    yield put({ type: "USERMEDIA_REQUEST_DATA_FAILURE", action: err});
  }
}

function* deleteUserMedia(action) {
  try {
    const { data, error } = yield call( Api.deleteUserMedia, action.payload.content );
    if ( data )
      yield put({ type: "USERMEDIA_DELETE_REQUEST_DATA_SUCCESS", action: data });
    else
      yield put({ type: "USERMEDIA_DELETE_REQUEST_DATA_FAILURE", action: error });
  } catch(err) {
    console.log("Error while deleting user media"+err);
    yield put({ type: "USERMEDIA_DELETE_REQUEST_DATA_FAILURE", action: err});
  }
}

export function* watchUpdateUserMedia() {
  yield takeLatest("USERMEDIA_REQUEST_DATA", updateUserMedia);

}
export function* watchDeleteUserMedia() {
  yield takeLatest("USERMEDIA_DELETE_REQUEST_DATA", deleteUserMedia);

}
