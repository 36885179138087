import React from 'react';
import { IconButton, Box, Button, Card, CardHeader, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';
import CustomizedMenus from '../post/actionmenu';

const GreenCheckbox = withStyles({
  root: {
    //color: green[400],
    '&$checked': {
      color: indigo[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default class FileUpload extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      file:null,
      anchorEl: null,
    }
  }

  onChange = (e) => {
    console.log("File selected: "+e.target.files[0]);
    //this.setState({file: URL.createObjectURL(e.target.files[0])});
    var data = new FormData();
    var payload = {
      media: ["twit", "fb"],
      content: "This is test",
    }
    data.append("payload", new Blob( [JSON.stringify(payload)], { type: "text/json" } ));
    data.append("image", e.target.files[0]);

    axios({
      method: "post",
      url: "http://localhost:8180/publish",
      headers: {"Content-Type": "multipart/form-data"},
      data: data
    })
    .then( function(response) {
      console.log("success: "+response);
    })
  }

  handlePublish = () => {
    this.setState( { anchorEl: null } );
    console.log("Publish now....");

  }
  handleClick = (event) => {
    this.setState( { anchorEl: event.currentTarget } );
  };

  handleClose = () => {
    this.setState( { anchorEl: null } );
  };

  render() {
    const inputEl = React.createRef();
    const { image } = this.state;
    const { anchorEl } = this.state;

    return(
      <>
      <div><br/>
        <form>
          <Box marginBottom={2}>
            <FormGroup>
              <FormControlLabel control={<GreenCheckbox name="checkedC" />} label="Test color" color="indigo"/>
            </FormGroup>
          </Box>
          <Box marginBottom={2}>
            <Button type="file" onClick={(e)=>{e.preventDefault(); inputEl.current.click();}}><AttachFileIcon color="primary"/></Button>
            <input type="file" accept="image/*" id="icon-upload" ref={inputEl} accept="image/*" className="img-none" onChange={this.onChange.bind()}/>
          </Box>
        </form>
        <div>
          Image:
          <img className="img-uploaded" src={ this.state.file }/>
        </div>
        <br/>
        <a target="_blank" href="https://google.com" >Testing</a>
        <br/>
        <CustomizedMenus anchorEl={anchorEl}
          handleClick={this.handleClick}
          handleClose={this.handleClose}
          handlePublish={this.handlePublish}  />
      </div>
      </>
    )
  }
}
