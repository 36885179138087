
export const checkLoadingStatus = () => ({
  type: "ISLOADING",
});

export const doneLoading = () => ({
  type: "LOADED"
});

export const loadData = () => ({
  type: "REQUEST_DATA"
  //do something
  //dispatch(doneLoading  );
});
