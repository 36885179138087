import { combineReducers } from 'redux';
import publisher from './index';
import auth from './login';
import media from './media';

export default combineReducers({
  publisher,
  auth,
  media
});
