
export const hasJSONValue = (json, findValue) => {
  if ( !json ) return false;
  const values = Object.values(json);
  //console.log(values);
  let hasValue = values.includes(findValue);
  values.forEach(function(value) {
      if (typeof value === "object") {
          hasValue = hasValue || hasJSONValue(value, findValue);
      }
  })
  return hasValue;
}

export const hasJSONArrayValue = (jarr, findValue) => {
  var found = false;
  if ( !Array.isArray(jarr) )
    return ;
  jarr.forEach((item, i) => {
    if ( hasJSONValue(item, findValue) ) {
      return true;
    }
  });
  return found;
}

export const getObjectFromArray = (jarr, field, value) => {
  //return if not array
  if ( !Array.isArray(jarr) )
    return;
  var res;
  jarr.forEach((item, i) => {
    if ( item[field]==value ) {
      res = item;
      return item;
    }
  });
  return res;
}
