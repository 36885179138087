import React from 'react';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import HalalistIcon from '../../images/hl';
import { Formik, Form, Field, ErrorMessage, useField } from 'formik';
import { Box, Checkbox, CheckboxProps, FormControlLabel, FormGroup, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { indigo } from '@material-ui/core/colors';
import { hasJSONValue } from '../../common/jsonutil';
import { useHistory } from 'react-router';
import Alert from '../alert/';

export function MediaList(props) {
   const { user }  = props;
   const { push } = useHistory();
   const [ alert, setAlert ] = React.useState(false);
   //const alert = React.useState(true);
  //console.log("Media list for user: "+JSON.stringify(user));
  const checkboxChange = (e) => {
    //console.log("Checkbox: "+e.target.value+" isChecked: "+e.target.checked);
    //
    const mn = e.target.value;
    //console.log("Does user has media configured: "+hasJSONValue(user, mn));
    if ( !hasJSONValue(user, mn) ) {
      //user doesnt have media configured
      //e.target.checked=false;
      //title="Media not configured";
      setAlert(true);
    }
  }

  return (
    <>
      <Box marginBottom={2}>
        <label>Select the platforms you want to publish to:</label>
        <FormGroup row>
          <MyCheckbox icon={<TwitterIcon />} checkedIcon={<TwitterIcon />}
            name="media" value="twit" color="primary" label="Twitter"
            onClick={checkboxChange} />
          <MyCheckbox icon={<FacebookIcon />} checkedIcon={<FacebookIcon />}
            name="media" value="fb" color="default" label="Facebook"
            onClick={checkboxChange}/>
          {/*
          <MyCheckbox icon={<InstagramIcon />} checkedIcon={<InstagramIcon />}
            name="media" value="insta" color="default" label="Instagram"
            onClick={checkboxChange}/>
          <MyCheckbox icon={<HalalistIcon />} checkedIcon={<HalalistIcon />}
            name="media" value="hl" color="default" label="Halalist"
            onClick={checkboxChange}/> */}
        </FormGroup>
        <Typography color="error"><ErrorMessage name="media" /></Typography>
      </Box>
      <Alert
        open={alert}
        handleClose={()=>{  setAlert(!alert) }}
        handleNext={() => {
          push( {
          pathname: "/settings/apikeys",
          state: { user: user }
          }) }}
        title="Media not configured"
        content="One of the media you selected is not configured. Please add API keys in settings to be able to publish to the media."
        closeLabel="Close"
        nextLabel="Add API Key"
        />
      </>
  );
}

export interface MyCheckboxProps extends CheckboxProps {
  name: string;
  value?: string | number;
  label?: string;
}

export function MyCheckbox(props: MyCheckboxProps) {
  const [field] = useField({
    name: props.name,
    type: 'checkbox',
    value: props.value,
    color: props.color
  });
  return (
    <FormControlLabel
      control={<CustomCheckbox {...props} {...field} />}
      label={props.label}
    />
  );
}

const CustomCheckbox = withStyles({
  root: {
    //color: green[400],
    '&$checked': {
      color: indigo[300],
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);
