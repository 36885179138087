import React from 'react';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    left: '50%',
    top: '50%',
    background: '#e9e9e9',
    //position: 'absolute',  // <- This and the following properties will
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.5,
    zIndex: 10004,
  },
  button: {
    margin: theme.spacing(2),
  },
  placeholder: {
    position: 'absolute',
    top: '50%',
  },
}));

export default function Loader(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(props.loading);
  const timerRef = React.useRef();

  React.useEffect( () => {
    //console.log("Loading mounted with loading: "+loading);
  }, []);

  // React.useEffect( () => {
  //       console.log("Using effect...");
  //       setTimeout(() => {
  //         console.log("Timeout called...");
  //         toggle();
  //       },4000);
  //     },
  //     [],
  //   );

  const toggle = () => {
    setLoading(!loading);
    if ( !loading ) {
      classes.root = '';
    }
  }
  return (
    <>
    { loading ?
    <div className={ classes.root }>
      <div className= { classes.placeholder }>
        <Fade
          in={loading}
          style={{
            transitionDelay: loading ? '800ms' : '0ms',
            marginHeight: '50%',
            top: '50%',
          }}
          unmountOnExit
        >
          <CircularProgress />
        </Fade>
      </div>
    </div>
    : '' }
    </>
  );
}
