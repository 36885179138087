import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Alert(props) {
  const open        = props.open;
  const handleClose = props.handleClose;
  const handleNext  = props.handleNext;
  const title       = props.title;
  const content     = props.content;
  const closeLabel  = props.closeLabel;
  const nextLabel   = props.nextLabel;
  const id          = props.id;
  const disableBackdropClick = props.disableBackdropClick;
  return (
    <>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        disableBackdropClick
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title-{id}">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          { closeLabel ?
            <Button onClick={handleClose} color="primary">
              {closeLabel}
            </Button> : '' }
          { nextLabel ?
            <Button onClick={handleNext} color="primary">
              {nextLabel}
            </Button> : '' }
        </DialogActions>
      </Dialog>
    </>
  );
}
