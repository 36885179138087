import React from 'react';
import { Typography } from '@material-ui/core';

export function Terms() {
  return (
    <>
    <Typography variant="caption" gutterBottom>
    You must be of legal age as determined by the local law of your residence.
You are solely responsible for your account password and the content of your post.
You are solely responsible for any interaction and exchanges between you and the other users.
It is the responsibility of the user to make sure that the content is appropriate to post on the publishing media and meets the requirement set by that media site.
Any content that might not meet the requirements could end up being flagged by that end media site and could cause publishing issues and limited functionality so please read the policy before posting to any media site.
Please do not spam any content, only use this service to post valuable, helpful content that could help your customer or general users.
You will not post any thing that could be considered offensive, racist, discriminatory, or illegal.
Please be respectful of others.
Any content posted to the end media site could be rejected by site for variety of reason.
Your use of this service means you agree to these terms.
</Typography>
    </>
  );
}
