import React, {Component} from 'react';
import SideBar from './sidebar.js';
import TopNav from './topnav';
import { withRouter } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { loadUser } from '../../actions/sidenav';
import { connect } from 'react-redux';

class AppNav extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    expanded: false,
    user: null
  };

  componentDidMount() {
    //console.log("Mounting navigation...");
    this.props.loadUser();
  }

  componentWillReceiveProps = (nextProps) => {
    //console.log("Props recv: "+nextProps.user);
    this.setState({ user: nextProps.user });
  }

  render() {
    const url = "#";
    //const history = useHistory();
    return (
        <>
        <div className="app">
          <TopNav url={url} history={this.props.history} user={this.state.user}/>
          <SideBar user={this.state.user} onChange={ (expanded)=> {
              console.log("State changed...."+expanded);
              this.setState( {expanded:expanded} );
            }}
            onSelect={ (selected)=> {
              console.log("Selected: "+selected+" current location: "+window.location.pathname);
              const to = "/"+selected;

              if ( window.location.pathname !== to ) {
                //this.props.history.push(to);
                if ( !this.state.user )
                  this.props.loadUser();
                this.props.history.push( {
                  pathname: to,
                  state: { user: this.state.user },
                });
              }
            }}
            />
          <div className={this.state.expanded?'maintoggle':'main'} >
            <div className="content-wrapper">
              {this.props.children}
            </div>
          </div>
        </div>
        </>
    );
  }
}
const mapStateToProps = ( state ) => {
  //console.log("mapStateToProps "+JSON.stringify(state.auth));
  const { user } = state.auth ;
  return {
    user
  }
}
const mapDispatchToProps = dispatch => ({
  loadUser: (user, pass, cb) => dispatch(loadUser())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppNav));
