import React from 'react';

export default (state={loading:false, response:null,}, action) => {
      //console.log("Init reducer "+action.type);

      if ( action.type == 'USERMEDIA_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'USERMEDIA_REQUEST_DATA_SUCCESS') {
        return { loading: false, response: action.action };
      }
      if ( action.type == 'USERMEDIA_REQUEST_DATA_FAILURE') {
        //console.log("Usermedia update request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }
      if ( action.type == 'USERMEDIA_DELETE_REQUEST_DATA') {
        return { loading: true };
      }
      if ( action.type == 'USERMEDIA_DELETE_REQUEST_DATA_SUCCESS') {
        return { loading: false, response: action.action };
      }
      if ( action.type == 'USERMEDIA_DELETE_REQUEST_DATA_FAILURE') {
        //console.log("Delete request failed: "+action.action+" loading: "+state.loading);
        return { loading: false, response: action.action };
      }

      return { loading: false };
}
