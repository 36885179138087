import React, {Component} from 'react';
import Routes from '../../routes';
import Loader from "../loader/";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as actions from '../../actions/loading.js';
import { ThemeProvider, createMuiTheme } from '@material-ui/core';

class App extends Component {

  componentDidMount() {
    //console.log(this.props.loading);
  }
  render() {
    const theme = createMuiTheme( {
      typography: {
        fontFamily: "Arial",
        fontWeightBold:800,
      }
    })
    const { location, isAuthenticated, loading } = this.props;
    return (
        <>
          <ThemeProvider theme={theme}>
            { loading ? <Loader loading={loading}/> : '' }
            <Routes />
          </ThemeProvider>  
        </>
    );
  }
}
App.propTypes = {
  // location: PropTypes.shape({
  //   pathname: PropTypes.string.isRequired
  // }).isRequired,
  //isAuthenticated: PropTypes.bool.isRequired,
  //fetchCurrentUserRequest: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,

};

function mapStateToProps(state) {
return {
  //isAuthenticated: !!state.user.email,
  loading: state.publisher.loading || state.auth.loading || state.media.loading ,

  };
};
//export default App;
export default connect(mapStateToProps, { actions })(App);
