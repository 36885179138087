import { call, put, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../common/api.js';

function* loadUser(action) {
  //console.log("Loading user "+action);

  try {
    //yield delay(2000); //this is just for testing purposes please remove

    const { data, error } = yield call( Api.loadUser );
    if ( data )
      yield put({ type: "USER_REQUEST_SUCCESS", user: data });
    else
      yield put({ type: "USER_REQUEST_FAILURE", error: error });
  } catch (err) {
    //callback(false);
    console.log("Error while loading user "+err);
    yield put({ type: "USER_REQUEST_FAILURE", error: err });
  }
}

export function* watchLoadUser() {
  yield takeLatest("USER_REQUEST", loadUser);

}
