import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';

const useStyles = (theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
  },
});

class Charts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,

    }

  }

  handleClick = (e) => {
    console.log("Button was clicked!");
    this.setState({
      anchorEl: this.state.anchorEl? null : e.currentTarget
    });
  }
  render() {
    const {classes} = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popper' : undefined;
    return (
      <div>
      <button onClick={this.handleClick}>Test it!</button>
        <Popper id={id} open={open} anchorEl={this.state.anchorEl}>
          <div className={classes.paper} >The content of the Popper.</div>
        </Popper>
      </div>
    );
  }
}
export default withStyles(useStyles)(Charts)
