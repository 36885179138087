import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {Post} from '../';

function getModalStyle() {
  const top = 50 ;
  const left = 50 ;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 600,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

export default function PostModal(props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const open = props.open;
  const handleOpen = props.handleOpen;
  const handleClose = props.handleClose;
  const user = props.user;
  const eventid = props.eventid;

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <p id="simple-modal-description">
        <Post user={user} eventid={eventid} history={props.history}/>
      </p>

    </div>
  );

  return(
    <>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
    {user && body}
    </Modal>

    </>
  )
}
