import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { mockdata } from '../actions/app.js';

function* requestLoadData() {
  console.log("Saga load data");
  try {
    yield delay(20000);
    const res = yield call(mockdata);
    yield put({ type: "REQUEST_DATA_SUCCESS", payload: res.data });

    //callback(true);
  } catch (err) {
    //callback(false);
    yield put({ type: "REQUEST_FAILURE" });
  }
}

export function* watchLoadData() {
  yield takeLatest("REQUEST_DATA", requestLoadData);

}
