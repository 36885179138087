import React, {Component } from 'react';
import { Box, Button, Card, CardHeader, CardContent, Checkbox, CheckboxProps,
  FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import { array, boolean, mixed, number, object, string } from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '../alert/';
import SendIcon from '@material-ui/icons/Send';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SaveIcon from '@material-ui/icons/Save';
import ErrorIcon from '@material-ui/icons/Error';
import DoneIcon from '@material-ui/icons/Done';
import { publish, save, loadScheduledContent } from './actions';
import { connect } from 'react-redux';
import { MediaList } from './medialist';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { loadUser } from '../../actions/sidenav';
import CustomizedMenus from './actionmenu';
import { PublishForm } from './publishform';
import SaveDialog from './save';

class Post extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isSubmitting: false,
      values: {title:"", media:"", content:""},
      initvalues: null,
      postsch: "",
      posttitle: "",
      response: "",
      user: ( props.user ? props.user : (props.location ? props.location.state.user : null) ),
      eventid: props.eventid,
      file:null,
      anchorEl:null,
      submitForm:null,
      saveForm:null,
      error:"",
      alert: {
        open: false,
        title: "Are you sure you want to publish this content?",
        content: "Selecting yes will publish content to the selected media platforms. Please review content, select yes to publish or no to cancel.",
        closeLabel: "No",
        nextLabel: "Yes",
        handleClose: this.closeAlert,
        handleNext: this.publishContent
      },
      dialog: {
        open: false,
        title: "",
        sch: false,
        handleClose: () => {
          //console.log("Dialog: "+this.state.dialog.open);
          this.setState( prevState => ( { dialog: { ...prevState.dialog, open: !this.state.dialog.open } } ) );
        },
        handleSave: () => {
          if ( !this.state.posttitle ) {
            this.setState({ error: "Please add title to continue" });
            return;
          }
          var vals = this.state.values;
          vals.title = this.state.posttitle;
          //scheduledate
          if ( this.state.postsch ) {
            vals.scheduledate = this.state.postsch;
          }
          //console.log("Saving..."+JSON.stringify(vals));
          this.setState( prevState => ( { dialog: { ...prevState.dialog, open: !this.state.dialog.open } } ) );
          this.waitForResults();
          this.props.save( { values:this.state.values, file: this.state.file}, () => {
            console.log("Response from server: "+this.props.response);
          });
        },
        handleTitle: (e) => {
          if ( e && e.target ) {
            var newtitle = e.target.value;
            //console.log("Updating: "+newtitle);
            this.setState( { posttitle: newtitle } );
          }
        },
        handleSch: (dt) => {
          if ( dt ) {
            var newsch = dt;
            //console.log("Updating sch: "+newsch);
            this.setState( { postsch: newsch } );
          }
        }
      }
    }
  }
  componentWillMount() {
    //console.log("Mounting navigation...");
    if ( !this.state.user ) {
      //console.log("Get obj...");
      this.props.loadUser();
    }
    if ( this.state.eventid ) {
      //need to show user event posts
      this.props.loadScheduledContent(this.state.eventid);
    }
  }

  componentWillReceiveProps = (nextProps) => {

    if ( nextProps.response ) {
      //console.log("Received props: "+JSON.stringify(nextProps.response));
      this.setState( { response: nextProps.response });
      this.showResults(nextProps.response);
    }

  }
  componentDidUpdate(prevProps) {
    //workaround since state is not guarenteed to be updated
    if ( this.props.user && (this.props.user!==prevProps.user) ) {
      this.setState({ user: this.props.user });
    }
    if ( this.props.content && (this.props.content !== prevProps.content) ) {
      var cvalue = { title: this.props.content.title, content: this.props.content.content, media: this.props.content.media };
      this.setState(prevState=> ( { initvalues: cvalue }) );
      this.setState({ posttitle: cvalue.title });
    }
  }
  showResults = (response) => {
    const result = response.result;
    var alert = this.state.alert;

    if ( result == "success" ) {
      alert.title = "We were successful in updating your contents! ";
      //alert.content = "We were successful in publishing your contents.";
      if ( response.details && Array.isArray(response.details) ) {
        alert.content = (response.details).map(function(jobj) {
          return (
            <div>
            {jobj.result!="success"?<ErrorIcon color="error" /> :<DoneIcon color="primary"/>} {jobj.details}
            </div>
          )
        });
      } else if ( response.details ) {
        alert.content = (
          <div>
          {<DoneIcon color="primary"/>} {response.details}
          </div>
        )
      }
      //alert.content= JSON.stringify(response);
    } else {
      alert.title= "There was a problem publishing...";
      //alert.content = "Please try again at a later time."
      alert.content= !Array.isArray(response.details) ? response.details : (response.details).map(function(jobj) {
        return (
          <div>
          {jobj.result!="success"?<ErrorIcon color="error" /> :<DoneIcon/>} {jobj.details}
          </div>
        )
      });
    }
    alert.closeLabel = "Close";
    alert.handleClose = () => {
      this.props.history.push( {
        pathname: "/",
        state: { user: this.state.user }
      })
    }
    this.setState({alert:alert});
  }

  waitForResults = () => {
    //console.log("Wait for results...");
    var alert = this.state.alert;
    alert.open = true;
    alert.title = "We are updating your content";
    alert.content = "Please wait...";
    alert.nextLabel = null;
    alert.closeLabel = null;

    this.setState( { alert: alert } );
  }

  closeAlert = () => {

      var alt = this.state.alert;
      alt.open=false; //we just need to update one attribute
      this.setState( { alert: alt, isSubmitting: false } );

  }

  publishContent = (event) => {
    //console.log("Publishing content");
    //first remove confirmation
    this.setState({ isSubmitting:true });
    this.waitForResults();
    this.props.publish( { values:this.state.values, file: this.state.file}, () => {
      console.log("Response from server: "+this.props.response);
    });

  }

  handleMenuSave = () => {

    this.setState( { anchorEl: null } );
    //console.log("Save now...."+JSON.stringify(this.state.values));
    if ( !this.state.values || !this.state.values.content || !this.state.values.media ) {
      return this.setState({ error: "Update content or select media to continue"})
    }
    //console.log(this.state.formikProps.validateForm(this.state.formikProps));
    //this.state.submitForm.validate();
    this.setState( prevState => ( {
      dialog: { ...prevState.dialog, open: true, title: "Save your post for later", sch:false, } }));
  }
  handleMenuSchedule = () => {
    this.setState( { anchorEl: null } );
    //console.log("Schedule now....");
    if ( !this.state.values || !this.state.values.content || !this.state.values.media ) {
      return this.setState({ error: "Update content or select media to continue"})
    }
    this.setState( prevState => ( {
      dialog: { ...prevState.dialog, open: true, title: "Schedule your post for later", sch: true, } }));
    //this.submit();
    //this.formikProps.submitForm;
  }
  handleMenuClick = (event) => {
    this.setState( { anchorEl: event.currentTarget } );
  };

  handleMenuClose = () => {
    this.setState( { anchorEl: null } );
  };

  handleClick = async (event, values) => {
    var alrt = this.state.alert;
    alrt.open=true;
    this.setState({
        alert: alrt,
        values: values
    });

  };
  handleSubmitForm = (e) => {
    //console.log("Submitting form...");
    if ( this.state.submitForm ) {
      this.state.submitForm(e);
      if ( this.state.values )
        this.handleClick(this, this.state.values);
      //
    }
  }

  bindSubmitForm = (form) => {
    //console.log("submit form");
    if ( !this.state.submitForm )
      this.setState( prevState => ({ ...prevState, submitForm: form }) ) ;
  }

  bindSaveForm = (formikProps) => {

    if ( formikProps.values && formikProps.values!=this.state.values  ) {
      //console.log("save form "+values);
      this.setState( { values: formikProps.values, formikProps: formikProps, error:"", });
    }
  }

  setFormValues = (values) => {
    //console.log("submit form");
      this.setState({ values: values, }) ;
  }
  onChange = (e) => {
    console.log("File selected: "+e.target.files[0]);
    this.setState({file: e.target.files[0], ourl: URL.createObjectURL(e.target.files[0])});
  }
  render() {
    const inputEl = React.createRef();
    const { anchorEl } = this.state;
    const classes = makeStyles((theme) => ({
      button: {
        margin: theme.spacing(1),
      },

    }));
    return (
      <>
        <br/>
      <Card>
        <CardHeader title="Post"></CardHeader>
        <CardContent>
          { ( !this.state.eventid || (this.state.initvalues) ) &&
            <PublishForm
              bindSubmitForm={this.bindSubmitForm}
              bindSaveForm={this.bindSaveForm}
              formValues={this.setFormValues}
              user={this.state.user}
              initvalues={this.state.initvalues?this.state.initvalues:this.state.values} />
          }
            <Box display="flex">
              <Box flexGrow={1}>
                <Button
                  className={classes.button}
                  type="button"
                  onClick={(e)=>{e.preventDefault(); inputEl.current.click();}}
                  endIcon={<AttachFileIcon/>}
                  variant="outlined"
                  color="primary">Add image
                </Button>
                <input type="file" accept="image/*" id="icon-upload" ref={inputEl} accept="image/*" className="img-none" onChange={this.onChange.bind()}/>
                &nbsp;
              </Box>
              <Box >
                <Button
                  className={classes.button}
                  endIcon={<SendIcon/>}
                  type="submit"
                  disabled={this.state.isSubmitting }
                  onClick={this.handleSubmitForm}
                  variant="contained"
                  color="primary">
                  Publish Now
                </Button> &nbsp;
                <CustomizedMenus anchorEl={anchorEl}
                  handleClick={this.handleMenuClick}
                  handleClose={this.handleMenuClose}
                  handleSave={this.handleMenuSave}
                  handleSchedule={this.handleMenuSchedule}
                  />
              </Box>
            </Box>
            <Typography color="error">{this.state.error}</Typography>
            <div>
              <img className="img-uploaded" src={this.state.ourl}/>
            </div>
            {/*
            <pre>{JSON.stringify(this.state.postsch, null, 4)}</pre>
            */}
          <Alert open={this.state.alert.open}
            handleClose={this.state.alert.handleClose}
            handleNext={this.state.alert.handleNext}
            title={this.state.alert.title}
            content={this.state.alert.content}
            closeLabel={this.state.alert.closeLabel}
            nextLabel={this.state.alert.nextLabel}
            />
          <SaveDialog open={this.state.dialog.open}
            handleClose={this.state.dialog.handleClose}
            handleSave={this.state.dialog.handleSave}
            title={this.state.dialog.title}
            sch={this.state.dialog.sch}
            posttitle={this.state.posttitle}
            postsch={this.state.postsch}
            handleTitle={this.state.dialog.handleTitle}
            handleSch={this.state.dialog.handleSch}
            />
        </CardContent>
      </Card>

      </>
    );
  }
}

const mapStateToProps = ( state ) => {
  console.log("PUblish resp "+state.publisher);
  const { response, content} = state.publisher ;
  const { user } = state.auth;
  return {
    response, user, content
  }
}
const mapDispatchToProps = dispatch => ({
  loadScheduledContent: (eventid) => dispatch(loadScheduledContent(eventid)) ,
  publish: (content) => dispatch(publish(content)),
  save: (content) => dispatch(save(content)),
  loadUser: () => dispatch(loadUser())
});
export default connect(mapStateToProps, mapDispatchToProps)(Post);
