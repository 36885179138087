import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';

export default function SaveDialog(props) {
  const open = props.open;
  const { title, handleClickOpen, handleClose, handleSave, sch, posttitle, handleTitle, handleSch } = props;
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const addZeros = (t) => {
    if ( t< 10 ) {
      t="0"+t;
    }
    return t;
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
    var sdate = moment(date).format("yyyy-MM-DD");
    sdate+=" "+addZeros(date.getHours())+":"+addZeros(date.getMinutes())+":"+date.getSeconds();
    console.log("Date: "+date+" formatted: "+sdate);
    handleSch(sdate);
  };
  return (
    <div>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please add a title to save this post.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="posttitle"
            value={posttitle}
            label="Title"
            type="text"
            fullWidth
            onChange = {handleTitle}
          />
          { sch &&
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              label="Date picker dialog"
              format="MM/dd/yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
            <KeyboardTimePicker
              margin="normal"
              id="time-picker"
              label="Time picker"
              value={selectedDate}
              onChange={handleDateChange}
              KeyboardButtonProps={{
                'aria-label': 'change time',
              }}
            />
          </MuiPickersUtilsProvider> }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
