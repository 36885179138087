import React, {Component} from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import Dash from '../components/dash/';
import Charts from '../components/charts/';
import {Post} from '../components/post/';
import Schedule from '../components/post/sch/';
import PostHistory from '../components/post/history/';
import Profile from '../components/profile/';
import Test from '../components/test/';
import APIKeys from '../components/settings/apikeys/';
//All application routes defined here
//These routes are displayed with in application navigation
//add new paths here
const appPaths = [
  {
    path: "/home",
    component: "Dash"
  },
  {
    path: "/chart",
    component: "<div>testing</div>"
  }
]

const hasAccess = (c) => {
  //user.access.find((e)=> (e.name === permission));
  console.log("Check if user has access to: "+c.name);
  //for now return true
  return true;
}

const SectionRoute = ({ component: Component, permission, history, ...rest }) => {


  return (
        <Route
            {...rest}
            render={ ( { location,history, props } ) =>
                hasAccess(location) ? (
                    <Component {...props} history={history}/>
                ) : (
                        <Redirect
                            to="/unauthorized"
                        />
                    )
            }
        />
    );
}

export class AppRoutes extends Component {

  render() {
    //console.log("Path: "+this.props);
    return (
      <Switch>
            <Route exact
              path="/"
              component={Dash}
            />
            <Route exact
              path="/home"
              component={Dash}
            />
            <Route exact
              path="/chart"
              component={Charts}
            />
            <Route exact
              path="/content/addpost"
              component={Post}
            />
            <Route exact
              path="/content/schedule"
              component={Schedule}
            />
            <Route exact
              path="/content/posthistory"
              component={PostHistory}
            />
            <Route exact
              path="/profile"
              component={Profile}
            />
            <Route exact
              path="/settings/apikeys"
              component={APIKeys}
            />
            <Route exact
              path="/testing"
              component={Test}
            />

            <Route path="*"
                    component={() => "404 Not Found!"}
            />
          </Switch>
    );
  }
}
