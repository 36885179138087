import React from 'react';
import { Box, Button, Card, CardHeader, CardContent, Checkbox, CheckboxProps, FormControlLabel, FormGroup, MenuItem, TextField, Typography } from '@material-ui/core';
import * as Api from '../../common/api.js';

export default function Profile(props) {
  const { user } = props.location.state;
  console.log("user: "+JSON.stringify(user));
  return (
    <>
    <br/>
    <Card>
      <CardHeader title="Profile"></CardHeader>
      <CardContent>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="Username" value={user.username} disabled fullWidth/>
          </FormGroup>
        </Box>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="First Name" value={user.firstname} disabled fullWidth/>
          </FormGroup>
        </Box>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="Last Name" value={user.lastname} disabled fullWidth/>
          </FormGroup>
        </Box>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <TextField label="Email" value={user.email} disabled fullWidth/>
          </FormGroup>
        </Box>
        <Box marginBottom={2}>
          <label></label>
          <FormGroup row>
            <Button type="submit" variant="outlined" color="primary"
              onClick={ () => {
                  props.history.push("/")
              }}>Cancel</Button>
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
    </>
  )
}
