import { fork, call, put, takeLatest, delay } from 'redux-saga/effects';
import * as Api from '../../common/api.js';

function* publish(action) {
  //console.log("publishing data with content "+req.content);
  try {
    //yield delay(4000); //this is just for testing purposes please remove
    const { data, error } = yield call( Api.publish, action.payload.content );
    if ( data )
    yield put({ type: "PUBLISH_REQUEST_DATA_SUCCESS", action: data });
    else {
      yield put({ type: "PUBLISH_REQUEST_DATA_FAILURE", action: error});
    }
    //callback(true);
  } catch (err) {
    //callback(false);
    console.log("Error while publishing "+err);
    yield put({ type: "PUBLISH_REQUEST_DATA_FAILURE", action: err});
  }
}

function* save(action) {
  //console.log("publishing data with content "+req.content);
  try {
    //yield delay(4000); //this is just for testing purposes please remove
    const { data, error } = yield call( Api.save, action.payload.content );
    if ( data )
    yield put({ type: "PUBLISH_REQUEST_DATA_SUCCESS", action: data });
    else {
      yield put({ type: "PUBLISH_REQUEST_DATA_FAILURE", action: error});
    }
    //callback(true);
  } catch (err) {
    //callback(false);
    console.log("Error while publishing "+err);
    yield put({ type: "PUBLISH_REQUEST_DATA_FAILURE", action: err});
  }
}

function* schedules(action) {
  try {
    const { data, error } = yield call( Api.schedules, action.payload.content);
    if ( data )
    yield put({ type: "SCHEDULE_REQUEST_DATA_SUCCESS", action: data });
    else {
      yield put({ type: "SCHEDULE_REQUEST_DATA_FAILURE", action: error});
    }
  } catch (err) {
    //callback(false);
    console.log("Error while getting schedules "+err);
    yield put({ type: "SCHEDULE_REQUEST_DATA_FAILURE", action: err});
  }
}

function* loadScheduledContent(action) {
  const { data, error } = yield call( Api.loadScheduledContent, action.payload.eventid);
  if ( data )
    yield put({ type: "SCHEDULE_LOAD_REQUEST_DATA_SUCCESS", action: data });
  else {
      yield put({ type: "SCHEDULE_LOAD_REQUEST_DATA_FAILURE", action: error});
  }

}

function* syncPublish(action) {
  //console.log("publishing data with content "+req.content);

  const { response, error } = yield call( Api.publish, action.payload.content );
  if ( response ) {
      //delay(2000);
      yield put({ type: "PUBLISH_REQUEST_DATA_SUCCESS", action: response });
  } else {
    console.log("Error while publishing "+error);
    yield put({ type: "REQUEST_FAILURE", action: error });
  }
}

export function* watchPublish() {
  yield takeLatest("PUBLISH_REQUEST_DATA", publish);
  yield takeLatest("SAVE_REQUEST_DATA", save);
  yield takeLatest("SCHEDULE_REQUEST_DATA", schedules);
  yield takeLatest("SCHEDULE_LOAD_REQUEST_DATA", loadScheduledContent);
}
