import React from 'react';
import { Typography, TextField, Box, InputAdornment, Button, TextareaAutosize, Checkbox, Link } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import EmailIcon from '@material-ui/icons/Email';
import Lock from '@material-ui/icons/Lock';
import Phone from '@material-ui/icons/Phone';
import Person from '@material-ui/icons/Person';
import BusinessIcon from '@material-ui/icons/Business';
import ChatIcon from '@material-ui/icons/Chat';
import loginImg from '../../images/socialutil.png';
import auth from '../../common/auth';
import { PhoneMask } from '../inputs/phonemask';
import { doregister } from '../../actions/login';
import { connect } from 'react-redux';
import FormHelperText from '@material-ui/core/FormHelperText';
import Alert from '../alert/';
import {Terms} from './terms';

class Register extends React.Component {

  constructor(props) {
    super(props);
    //console.log("Login location props: "+props.location);
    this.state = {
      alert: false,
      email: "",
      password: "",
      cpassword: "",
      firstname: "",
      lastname: "",
      phone: {
        mask: "(   )   -    ",
      },
      company: "",
      reasons: "",
      termscheck: false,
      isValidating: false,
      isSubmitting: false,
      error: "",
      isSuccess: false,
    }
  }

  handleClick = (e) => {
    this.setState({ isValidating: true });
    const { email, firstname, lastname, password, cpassword, termscheck } = this.state;
    if ( !email || !firstname || !lastname || !password || !cpassword || !termscheck ) {
      return; //dont submit
    }

    if ( password && password.length<6 ) {
      this.setState({ error: "Password lenght must be greater than 6"});
      return;
    }

    if ( password!==cpassword ) {
      this.setState({ error: "Password dont match!"});
      return;
    }
    this.setState({ isValidating: false, isSubmitting: true, error: "" });
    //now posting
    this.props.doregister({ firstname: firstname, lastname: lastname, email: email,
      phone: this.state.phone.mask ? "" : this.state.phone, company: this.state.company, password: this.state.password,
      reason: this.state.reason }, (response) => {
        console.log("Response from register: "+response);
        if ( !response ) {
          this.setState({ error: "Unable to register. Please try again later.", isSubmitting: false});
        } else if ( response && response.result=="fail" ) {
            this.setState({ error: response.details+" ",
              isSubmitting: false});
        } else if ( response.result=="success" ) {
          this.setState({ isSuccess: true });
        }
      });
  }

  render() {
    const { isValidating, isSubmitting, email, password, cpassword, firstname, lastname } = this.state;
    return (
    <div className="base-container">
        <div className="header">{/*<Typography variant="h5">Register</Typography>*/}</div>
        <div className="content">
            <div className="image">
              <img src={loginImg} />
            </div>
            { this.state.isSuccess
              ? <>We are processing your request, we will email you once your account is ready for usage. </>
            : <>
                    <div className="form">

                      <div className="form-group">
                        <TextField label="First name" name="firstname"
                          error={isValidating && !firstname}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({firstname: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Last name" name="lastname"
                          error={isValidating && !lastname}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Person />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({lastname: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Phone" name="phone" value={this.state.phone.mask}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Phone />
                              </InputAdornment>
                            ),
                            inputComponent: PhoneMask,
                          }}
                          onChange={(evt) => { this.setState({phone: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Company" name="company"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <BusinessIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({company: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Reasons" name="reasons"
                          placeholder="Describe the reasons for using this service"
                          rows="2"
                          rowsMax="6"
                          multiline
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <ChatIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({reasons: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Email" name="email"
                          error={isValidating && !email}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <EmailIcon />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({email: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Password" name="password" type="password"
                          error={isValidating && !password}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({password: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <TextField label="Confirm Password" name="cpassword" type="password"
                          error={isValidating && !cpassword}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Lock />
                              </InputAdornment>
                            ),
                          }}
                          onChange={(evt) => { this.setState({cpassword: evt.target.value, erruser: ""});  } }
                          fullWidth/>
                      </div>
                      <div className="form-group">
                        <Checkbox
                              checked={this.state.termscheck}
                              color="primary"
                              onChange={(e) => { this.setState( { termscheck: !this.state.termscheck }) }}
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          I agree to <Link href="#" onClick={()=>{this.setState({alert:true})}}>terms</Link> of service
                      <FormHelperText className="error">{isValidating && !this.state.termscheck && "Please agree to terms of service"}</FormHelperText>
                      </div>
                      <div className="footer">
                        <Box marginTop={2}>
                          <Button variant="outlined" color="primary" type="submit"
                            disabled={this.state.isSubmitting }
                            onClick={this.handleClick}>Register</Button>
                          <Typography color="error">{this.state.error}</Typography>
                        </Box>
                      </div>
                  </div>
                  <Alert
                    open={this.state.alert}
                    title="Terms of service"
                    handleClose = {()=> { this.setState({alert:!alert})}}
                    content=<Terms/>
                    closeLabel="Close"
                    />
              </>
            }
        </div>
      </div>
    )
  }
}
const mapStateToProps = ( state ) => {
  //console.log("mapStateToProps "+state.auth);
  const { response } = state.auth ;
  return {
    response
  }
}
const mapDispatchToProps = dispatch => ({
  doregister: (data, cb) => dispatch(doregister(data, cb))
});
export default connect(mapStateToProps, mapDispatchToProps)(Register);
