export const publish = ( content ) => ({
  type: "PUBLISH_REQUEST_DATA",
  payload: { content }
});
export const save = ( content ) => ({
  type: "SAVE_REQUEST_DATA",
  payload: { content }
});
export const schedules = ( content ) => ({
  type: "SCHEDULE_REQUEST_DATA",
  payload: { content }
});
export const loadScheduledContent = ( eventid ) => ({
  type: "SCHEDULE_LOAD_REQUEST_DATA",
  payload: { eventid }
});
